<template>
  <v-container class="division_bar">
    <v-row>
      <v-col cols="8">
        <h1 class="System_admin_text_division ml-2">Open Uploads By Deal</h1>
      </v-col>

      <v-col cols="4" class="flex-end d-flex">
        <b-button @click="exportToExcel" variant="outline-primary">
          Export to Excel
        </b-button>
      </v-col>
    </v-row>

    <v-row> </v-row>

    <v-col cols="12" class="customer-search-table">
      <v-col cols="12" class="customer-search-table">
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1 cursor-pointer"
        ></v-data-table>
      </v-col>
    </v-col>

    <v-row>
      <v-col cols="12">
        <div class="header-add-systemAdmin" style="float: right">
          <router-link :to="'/Reports'"><span>Back</span></router-link>
        </div>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";
import XLSX from "xlsx";

export default {
  data() {
    return {
      componentURL: `OpenUploadsByDeal/?user_id=${localStorage.getItem(
        "userid"
      )}`,

      //sortOptions: { columns: [{ field: 'OrderID', direction: 'Ascending' }, { field: 'ShipCity', direction: 'Descending' }] },

      canviewreports: localStorage.getItem("canviewreports"),
      isReporter: localStorage.getItem("Reporter"),
      valid: false,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,

      headers: [
        { text: "Deal", value: "deal_name", sortable: true },
        { text: "Folder", value: "folder_name", sortable: true },
        { text: "File", value: "file_name", sortable: true },
        { text: "Broker", value: "broker", sortable: true },
        { text: "Comments", value: "comments", sortable: true },
      ],

      items: [],

      //sortBy: 'source_count',
      //sortDesc: true,
    };
  },

  created() {
    // this.checkPermissions();

    CommonServices.listWithParams(this.componentURL, this.items)
      .then((response) => {
        this.items = response.results;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    checkPermissions() {
      if (this.canviewreports == "false") this.$router.push("/");
    },

    exportToExcel() {
      // On Click Excel download button
      // export json to Worksheet of Excel
      var reportWS = XLSX.utils.json_to_sheet(this.items);

      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheets to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, reportWS, "Open Uploads By Deal"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Open Uploads By Deal.xlsx");
    },
  },
};
</script>

<style scoped>
.flex-end {
  justify-content: flex-end;
}

.header-add-division {
  text-align: right;
  margin-left: 0%;
}

.header-add-division button {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #20e0a5;
  box-shadow: none !important;
  color: #fff !important;
  padding: 8px 12px !important;
  border-radius: 60px;
}

.header-add-division:focus,
.header-add-division button:focus {
  outline: none !important;
}

.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

@media only screen and (max-width: 768px) {
  .header-add-division {
    margin-left: 0 !important;
  }
}
</style>
